<template>
  <a-spin :spinning="crspinning">
    <div style="width:100%;">
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="copy" />&nbsp;{{datailInfo.realName}}详情查看
        </div>
      </div>
      <a-card title="基础信息" :bordered="false" style="width: 95%">
        <a-row :gutter="24">
          <a-col span="3">
            <div class="up-item">
              <span class="up-item-label">姓名：</span>
              <span class="up-item-value">{{datailInfo.realName}}</span>
            </div>
          </a-col>
          <a-col span="7">
            <div class="up-item">
              <span class="up-item-label">身份证号码：</span>
              <span class="up-item-value">{{datailInfo.cardNo}}</span>
            </div>
          </a-col>
          <a-col span="7">
            <div class="up-item">
              <span class="up-item-label">项目名称：</span>
              <span class="up-item-value">{{datailInfo.projectName}}</span>
            </div>
          </a-col>
          <a-col span="7">
            <div class="up-item">
              <span class="up-item-label">协议名称：</span>
              <span class="up-item-value">{{datailInfo.agreementName}}</span>
            </div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="比对结果" :bordered="false" style="width: 95%">
        <a-table bordered 
          :data-source="dataSource" 
          :columns="columns"
          size="small">
        </a-table>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
  import { ModalComponentBase } from "@/shared/component-base";
  import { CommonServiceProxy } from "@/shared/common-service";
  export default {
    name: 'checkresultdetail',
    mixins: [ModalComponentBase],
    data () {
      return {
        crspinning: false,
        datailInfo: {},
        commonService: null,
        dataSource: [],
        columns: [
          { title: '比对内容', dataIndex: 'content' },
          { title: '系统内容', dataIndex: 'system' },
          { title: '导入内容', dataIndex: 'import' },
          { title: '是否一致', dataIndex: 'result' }
        ]
      };
    },
    methods: {
      getDetail() {
        this.crspinning = true;
        this.commonService.get({
          url: '/api/services/app/UPersonSignCheck/CheckSignDetail',
          params: {
            id: this.id
          }
        }).then(res => {
          this.crspinning = false;
          this.datailInfo = res;
          this.dataSource.splice(0, this.dataSource.length);
          if (res.compareType === 1) {
            this.dataSource.push(this.getObject(res, 'inDate', '入职日期'));
          } else if(res.compareType === 2) {
            this.dataSource.push(this.getObject(res, 'departureApplyDate', '离职申请日期'));
            this.dataSource.push(this.getObject(res, 'departureDate', '离职日期'));
          } else {
            this.dataSource.push(this.getObject(res, 'storeName', '仓名称'));
            this.dataSource.push(this.getObject(res, 'storeArea', '仓所属大区'));
            this.dataSource.push(this.getObject(res, 'storeProvince', '仓所属省'));
            this.dataSource.push(this.getObject(res, 'storeCity', '仓所属市'));
            this.dataSource.push(this.getObject(res, 'position', '职位'));
            this.dataSource.push(this.getObject(res, 'usedType', '用工类型'));
            this.dataSource.push(this.getObject(res, 'misNumber', 'mis号'));
            this.dataSource.push(this.getObject(res, 'customSendInDate', '客户推送日期（入职）'));
            this.dataSource.push(this.getObject(res, 'trialStartDate', '试用开始日期'));
            this.dataSource.push(this.getObject(res, 'trialEndDate', '试用结束日期'));
            this.dataSource.push(this.getObject(res, 'contractStartDate', '合同开始日期'));
            this.dataSource.push(this.getObject(res, 'contractEndDate', '合同结束日期'));
            this.dataSource.push(this.getObject(res, 'customSendOutDate', '客户推送日期（离职）'));
            this.dataSource.push(this.getObject(res, 'departureType', '离职类型'));
            this.dataSource.push(this.getObject(res, 'departureProperty', '离职属性'));
            this.dataSource.push(this.getObject(res, 'inDate', '入职日期'));
            this.dataSource.push(this.getObject(res, 'departureApplyDate', '离职申请日期'));
            this.dataSource.push(this.getObject(res, 'departureDate', '离职日期'));
          }
        }).catch(e => {
          console.error(e);
          this.crspinning = false;
        });
      },
      getObject (row, key, name) {
        const data = {
          id: key,
          content: name,
          system: '',
          import: '',
          result: '不一致'
        };
        if (row[key]) {
          data.import = row[key]
        }
        if (row.result && row.result[key]) {
          data.system = row.result[key];
        }
        if (data.system == data.import) {
          data.result = '一致'
        }
        return data;
      }
    },
    created() {
      this.fullData(); // 模态框必须,填充数据到data字段
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this.getDetail();
    }
  }
</script>
<style>
.up-item{
  width:100%;
}
.up-item-label {
  display: block;
  font-size: 14px;
}
.up-item-value {
  display:block;
  margin-top: 5px;
  font-weight: 600;
  font-size: 15px;
}
</style>