<template>
  <a-spin :spinning="csispinning">
    <div class="up-tabs">
      <a-tabs :activeKey="CurrentTabKey" @change="HandleTabChange">
        <a-tab-pane key="import" tab="导入信息">
          <div class="up-tab-content">
            <a-row :gutter="24">
              <a-col span="24">
                <a-button type="default" icon="import" class="up-btn up-blue" @click="handleImport">导入</a-button>
                <a-button type="default" icon="download" class="up-btn up-green" @click="handleExport">导出</a-button>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="up-search">
              <a-col span="5">
                <a-input placeholder="请输入提交人" :allowClear="true" v-model="request.submitUser" />
              </a-col>
              <a-col span="5">
                <a-range-picker @change="searchDateChange" v-model="rangeDate" :placeholder="[l('开始日期'), l('结束日期')]" valueFormat="YYYY-MM-DD" />
              </a-col>
              <a-col span="5">
                <a-button type="default" class="up-btn up-blue" icon="search" @click="searchData">查询</a-button>
                <a-button type="default" icon="sync" @click="resetData">重置</a-button>
              </a-col>
            </a-row>
            <ele-table
              :key="'checksigntable'"
              :columns="tableColumns"
              :table-data="tableData"
              :total-items="totalItems"
              :is-full="true"
              :current-page="pageNumber"
              :hide-row-selection="true"
              :max-height="tableMaxHeight"
              :handleHeight="1"
              @emitRefreshData="clearFilterAndRefresh"
              @emitSelectChange="updateSelectChange"
              @emitOnPageChange="updatePageChange"
              @emitShowSizeChange="updateShowSizeChange"
              :pageSizes="[20, 40, 60, 80]"
              :pageSize="20"
            >
            </ele-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="result" tab="核对结果">
          <div class="up-tab-content">
            <CheckResultList />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from '@/components/ele-table';
import moment from 'moment';
import { fileDownloadService } from "@/shared/utils";
// import { locale } from 'ant-design-vue/es/date-picker/locale/zh_CN';
import CheckResultList from './checkresult.vue';
import ImportForm from './import.vue';

export default {
  mixins: [AppComponentBase],
  components: {
    EleTable,
    CheckResultList
  },
  data () {
    const height = window.screen.availHeight - 430;
    return {
      commonService: null,
      tableMaxHeight: height,
      csispinning: false,
      rangeDate: [],
      tableColumns: [
        {
          title: "提交人",
          dataIndex: "submitUser",
          sorter: false,
          align: "center",
          width: 100,
          showOverflowTooltip: true,
          scopedSlots: { customRender: "submitUser" },
        },
        { 
          title: "提交时间",
          dataIndex: "submitTime",
          sorter: false,
          align: "center",
          showOverflowTooltip: true,
          width: 150,
          type: 'time',
          scopedSlots: { customRender: "submitTime" },
        },
        { 
          title: "项目名称",
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "projectName" },
        },
        { 
          title: "项目编码",
          dataIndex: "projectCode",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "projectCode" },
        },
        { 
          title: "协议名称",
          dataIndex: "agreementName",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "agreementName" },
        },
        { 
          title: "姓名",
          dataIndex: "realName",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "realName" },
        },
        { 
          title: "证件类型",
          dataIndex: "cardType",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "cardType" },
        },
        { 
          title: "证件号码",
          dataIndex: "cardNo",
          sorter: false,
          align: "center",
          showOverflowTooltip: true,
          width: 150,
          scopedSlots: { customRender: "cardNo" },
        },
        { 
          title: "手机号",
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "phoneNumber" },
        },
        { 
          title: "性别",
          dataIndex: "sex",
          sorter: false,
          align: "center",
          width: 80,
          scopedSlots: { customRender: "sex" },
        },
        { 
          title: "年龄",
          dataIndex: "age",
          sorter: false,
          align: "center",
          width: 80,
          scopedSlots: { customRender: "age" },
        },
        { 
          title: "仓所属大区",
          dataIndex: "storeArea",
          sorter: false,
          align: "center",
          width: 120,
          showOverflowTooltip: true,
          scopedSlots: { customRender: "storeArea" }
        },
        { 
          title: "仓所属省份",
          dataIndex: "storeProvince",
          sorter: false,
          align: "center",
          width: 100,
          showOverflowTooltip: true,
          scopedSlots: { customRender: "storeProvince" }
        },
        { 
          title: "仓所属城市",
          dataIndex: "storeCity",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "storeCity" },
        },
        { 
          title: "仓名称",
          dataIndex: "storeName",
          sorter: false,
          align: "center",
          width: 120,
          scopedSlots: { customRender: "storeName" },
        },
        { 
          title: "岗位",
          dataIndex: "position",
          sorter: false,
          align: "center",
          width: 120,
          showOverflowTooltip: true,
          scopedSlots: { customRender: "position" }
        },
        { 
          title: "用工类型",
          dataIndex: "usedType",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "usedType" },
        },
        { 
          title: "mis号",
          dataIndex: "misNumber",
          sorter: false,
          align: "center",
          width: 120,
          showOverflowTooltip: true,
          scopedSlots: { customRender: "misNumber" }
        },
        { 
          title: "客户推送日期（入职）",
          dataIndex: "customSendInDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "customSendInDate" },
          type: 'date'
        },
        { 
          title: "入职日期",
          dataIndex: "inDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "inDate" },
          type: 'date'
        },
        { 
          title: "试用开始日期",
          dataIndex: "trialStartDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "trialStartDate" },
          type: 'date'
        },
        { 
          title: "试用结束日期",
          dataIndex: "trialEndDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "trialEndDate" },
          type: 'date'
        },
        { 
          title: "合同开始日期",
          dataIndex: "contractStartDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "contractStartDate" },
          type: 'date'
        },
        { 
          title: "合同结束日期",
          dataIndex: "contractEndDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "contractEndDate" },
          type: 'date'
        },
        { 
          title: "客户推送日期（离职）",
          dataIndex: "customSendOutDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "customSendOutDate" },
          type: 'date'
        },
        { 
          title: "离职申请日期",
          dataIndex: "departureApplyDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "departureApplyDate" },
          type: 'date'
        },
        { 
          title: "离职类型",
          dataIndex: "departureType",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "departureType" }
        },
        { 
          title: "离职属性",
          dataIndex: "departureProperty",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "departureProperty" }
        },
        { 
          title: "离职日期",
          dataIndex: "departureDate",
          sorter: false,
          align: "center",
          width: 150,
          scopedSlots: { customRender: "departureDate" },
          type: 'date'
        }
      ],
      tableData: [],
      totalItems: 0,
      pageNumber: 1,
      request: {
        skipCount: 0,
        maxResultCount: 20,
        submitUser: null,
        startTime: undefined,
        endTime: undefined
      },
      CurrentTabKey: 'import',
      DepartureStatus: [
        { label: '正常离职', value: 1 },
        { label: '闪离', value: 2 }
      ],
      NotSignDays: [
        { label: '未签约天数>3', value: 1 },
        { label: '未签约天数<=3', value: 2 },
      ],
            // 共多少页
      totalPages: 1,
    }
  },
  methods: {
    searchDateChange (e) {
      if (e && e.length > 0) {
        this.request.startTime = e[0];
        this.request.endTime = e[1];
      } else {
        this.request.startTime = undefined;
        this.request.endTime = undefined;
      }
    },
    HandleTabChange(key) {
      this.CurrentTabKey = key;
      switch(key) {
        case 'import':
          this.searchData();
          break;
        case 'result':
          break;
      }
    },
    resetData() {
      this.request.startTime = undefined;
      this.request.endTime = undefined;
      this.request.submitUser = null;
      this.rangeDate.splice(0, this.rangeDate.length);
      this.searchData();
    },
    clearFilterAndRefresh() {
      this.resetData();
      this.searchData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
          console.log(page);
      console.log(pageSize);
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.searchData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.searchData();
    },
    searchData() {
      this.csispinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSignCheck/GetPage',
        params: {
          ...this.request
        }
      }).then(res => {
        // debugger;
        this.csispinning = false;
        this.tableData.splice(0, this.tableData.length);
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        console.log(this.pagerange);
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
          console.log(this.totalPages);
        res.items.map(item => {
          this.tableData.push(item);
          return null;
        });
      }).catch(e => {
        this.csispinning = false;
        console.error(e);
      })
    },
    checkSelectRows(statusArray, message) {
      if (!this.selectedRowKeys || this.selectedRowKeys.length <= 0) {
        abp.message.warn('至少选择一行');
        return false;
      }
      var rows = this.selectedRows.filter(item => statusArray.filter(t => t == item.signStatus).length <= 0);
      if (rows.length > 0) {
        abp.message.warn(message);
        return false;
      }
      return true;
    },
    handleImport () {
      ModalHelper.create(ImportForm, {}, 
        {
          isChange: true,
          width: "600px",
          heigth: "400px",
        }
      ).subscribe(res => {
        this.searchData();
      });
    },
    handleExport () {
      this.csispinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSignCheck/ExportExcel',
        params: {}
      }).then(res => {
        fileDownloadService.downloadTempFile(res);
      }).finally(() => {
        this.csispinning = false;
      });
    }
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.searchData();
  }
}
</script>
<style>
  .up-tab-content{
    height: 100vh - 200;
  }
  .up-btn {
    margin-right: 7px;
  }

  .up-warn {
    background-color: rgb(237,173,15);
    color: white;
  }
  .up-danger {
    background-color: crimson;
    color: white;
  }
  .up-blue {
    background-color: rgb(30,159,225);
    color: white;
  }
  .up-green {
    background-color: rgb(0,150,136);
    color: white;
  }
  .up-purple {
    background-color: rgb(78,115,223);
    color: white;
  }
  .up-search{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .up-table{
    width: 99.5%;
  }
</style>

