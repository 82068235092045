<template>
  <a-spin :spinning="crsspinning">
    <ele-table :key="'crtable'" :columns="tableColumns" :table-data="tableData" :total-items="totalItems"
      :is-full="true" :current-page="pageNumber" :hide-row-selection="false" :max-height="tableMaxHeight"
      :height="tableMaxHeight" :actionsType="actionsType" :IsClear="IsClear" @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange" pageSizes="[20, 40, 60, 80]" pageSize="20">
      <a-row :gutter="24">
        <a-col span="24">
          <a-button type="default" icon="import" :loading="httpLoading" class="up-btn up-blue"
            @click="batchSignture">批量盖章</a-button>
          <a-button type="default" icon="download" :loading="httpLoading" class="up-btn up-purple"
            @click="batchChangeSignture">变更并通知签署</a-button>
          <a-button type="default" icon="download" :loading="httpLoading" class="up-btn up-green"
            @click="handleExport">导出</a-button>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="up-search">
        <a-col span="8">
          <a-select v-model="request.projectAgreementId" placeholder="请选择协议" class="up-item-select">
            <a-select-option v-for="item in AllProjectAgreements" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-col>
        <a-col span="4">
          <a-select v-model="request.checkResult" placeholder="请选择核对结果" class="up-item-select">
            <a-select-option :value="2">信息一致</a-select-option>
            <a-select-option :value="1">信息不一致</a-select-option>
            <!-- <a-select-option :value="3">系统无此人</a-select-option>
            <a-select-option :value="4">系统无此协议</a-select-option> -->
          </a-select>
        </a-col>
        <a-col span="4">
          <a-input v-model="request.keyword" placeholder="姓名/身份证号/手机号" />
        </a-col>
        <a-col span="4">
          <a-select v-model="request.depatureProperty" placeholder="请选择离职属性" class="up-item-select">
            <a-select-option value="正常离职">正常离职</a-select-option>
            <a-select-option value="闪离">闪离</a-select-option>
          </a-select>
        </a-col>
        <a-col span="4">
          <a-button type="default" class="up-btn up-blue" icon="search" @click="searchData">查询</a-button>
          <a-button type="default" icon="sync" @click="resetData">重置</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from '@/components/ele-table';
import { fileDownloadService } from "@/shared/utils";
import DetailInfo from './checkresultdetail.vue';
import checkAgeFunc from '@/components/PersonAgeTip/check.js';
import shouldShowSealError from '@/components/CanSealInfo/check.js';

export default {
  name: 'checkresult',
  mixins: [AppComponentBase],
  components: {
    EleTable
  },
  data () {
    const height = window.screen.availHeight - 450;
    return {
      tableColumns: [],
      tableData: [],
      pageNumber: 1,
      totalItems: 0,
      tableMaxHeight: height,
      actionsType: null,
      crsspinning: false,
      httpLoading: false,
      IsClear: false,
      selectedRowKeys: [],
      selectedRows: [],
      request: {
        maxResultCount: 20,
        skipCount: 0,
        checkResult: undefined,
        projectAgreementId: undefined,
        keyword: '',
        depatureProperty: undefined
      },
      AllProjectAgreements: []
    }
  },
  methods: {
    clearFilterAndRefresh () {
      this.resetData();
      this.searchData();
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.searchData();
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.searchData();
    },
    resetData () {
      this.request.checkResult = undefined;
      this.request.projectAgreementId = undefined;
      this.request.keyword = '';
      this.request.depatureProperty = undefined;
      this.searchData();
    },
    searchData () {
      this.crsspinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSignCheck/GetCheckResultPage',
        params: {
          ...this.request
        }
      }).then(res => {
        this.crsspinning = false;
        this.IsClear = !this.IsClear;
        this.tableData.splice(0, this.tableData.length);
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        res.items.map(item => {
          this.tableData.push(item);
          return null;
        });
      }).catch(e => {
        this.crsspinning = false;
        console.error(e);
      })
    },
    /**
     * 批量盖章
     */
    batchSignture () {
      if (this.checkSelectRows()) {
        var errors = this.selectedRows.filter(it => it.signStatus != 'Simple' && it.signStatus != 'Signed');
        if (errors && errors.length > 0) {
          abp.message.warn('只有【已签署】或【单方签署】状态的记录才能执行此操作')
          return false;
        }
        var rows = this.selectedRows.filter(it => it.checkResult != 'Consistent');
        if (rows && rows.length > 0) {
          abp.message.warn('只有校对结果为【信息一致】的记录才能执行此操作')
          return false;
        }
        this.httpLoading = true;
        let data = {
          ids: [...this.selectedRowKeys]
        };
        this.commonService.post({
          url: '/api/services/app/UPersonSignCheck/BatchCheck',
          params: data
        }).then(res => {
          let errors = res.filter(it => it.isSuccess == false);
          if (!errors || errors.length <= 0) {
            abp.message.success('操作成功');
            this.searchData();
          } else {
            // let msg = '';
            // errors.map(it => {
            //   let rows = this.selectedRows.filter(i => i.id == it.id);
            //   if (rows && rows.length > 0) {
            //     let row = rows[0];
            //     msg += `【${row.realName}】【${row.agreementName}】${it.reason}，\r\n`;
            //   }
            //   return null;
            // });
            // abp.message.error(msg);
            shouldShowSealError({ tableData: res }, () => {
              this.searchData();
            });
          }
          this.httpLoading = false;
        }).catch(e => {
          this.httpLoading = false;
        });
      }
    },
    /**变更并签署 */
    async batchChangeSignture () {
      if (this.checkSelectRows()) {
        this.httpLoading = true;
        try {
          const params = {
            projectAgreementId: 'ECD910B6-7A3F-32E2-BA4E-87E0B4209019',
            personnelIds: [],
            personInfos: [],
            infos: this.selectedRows.map(it => {
              return {
                projectAgreementId: it.projectAgreementId,
                personnelId: it.personnelId
              }
            }),
            showAll: true
          };
          await checkAgeFunc(params, (res) => {
            if (res) {
              this.realBatchChangeSignture();
            }
          });
        } catch (e) {
          console.error(e);
          this.httpLoading = false;
        } finally {
          this.httpLoading = false;
        }
      }
    },
    realBatchChangeSignture () {
      const hide = this.$message.loading('请求中...', 0);
      let data = {
        ids: [...this.selectedRowKeys]
      };
      this.commonService.post({
        url: '/api/services/app/UPersonSignCheck/ChangeNSign',
        params: data
      }).then(res => {
        this.httpLoading = false;
        if (res && res != '') {
          abp.message.error(res);
          return false;
        }
        abp.message.success('操作成功');
        this.searchData();
      }).catch(e => {
        this.httpLoading = false;
      }).finally(() => {
        hide();
      });
    },
    /**导出 */
    handleExport () {
      let data = {
        ...this.request
      };
      this.crsspinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSignCheck/CheckSignExport',
        params: data
      }).then(res => {
        this.crsspinning = false;
        fileDownloadService.downloadTempFile(res);
      }).catch(e => {
        this.crsspinning = false;
        console.error(e);
      })
    },
    checkSelectRows () {
      if (!this.selectedRowKeys || this.selectedRowKeys.length <= 0) {
        abp.message.warn('至少选择一行');
        return false;
      }

      return true;
    },
    getAllProjectAgreements () {
      let res = this.commonService.get({
        url: '/api/services/app/UPersonSignCheck/GetUserAllProjectAgreements'
      }).then(res => {
        this.AllProjectAgreements.splice(0, this.AllProjectAgreements.length);
        if (res && res.length > 0) {
          res.map(it => {
            this.AllProjectAgreements.push(it);
            return null;
          });
        }
      });
    }
  },
  created () {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.tableColumns = [
      { title: "项目名称", dataIndex: "projectName", scopedSlots: { customRender: "projectName" }, width: 100, showOverflowTooltip: true },
      { title: "协议名称", dataIndex: "agreementName", scopedSlots: { customRender: "agreementName" }, width: 100, showOverflowTooltip: true },
      {
        title: "前置协议签署状态",
        dataIndex: "preAgreementStatus",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "preAgreementStatus" },
      },
      {
        title: "前置协议",
        dataIndex: "preAgreements",
        sorter: false,
        align: "center",
        width: 180,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "preAgreements" },
        type: 'custom',
        customRender: (text, item) => {
          if (text && text.length > 0) {
            let html = '';
            text.map(item => {
              html += item.agreementName + '，';
              return null;
            });
            if (html.length > 0) {
              html = html.substring(0, html.length - 1);
            }
            return html;
          }
          return '-';
        }
      },
      { title: "签约公司", dataIndex: "signCompany", scopedSlots: { customRender: "signCompany" }, width: 100, showOverflowTooltip: true },
      { title: "姓名", dataIndex: "realName", scopedSlots: { customRender: "realName" }, width: 100, showOverflowTooltip: true },
      { title: "身份证号", dataIndex: "cardNo", scopedSlots: { customRender: "cardNo" }, width: 100, showOverflowTooltip: true },
      { title: "手机号", dataIndex: "phoneNumber", scopedSlots: { customRender: "phoneNumber" }, width: 100, showOverflowTooltip: true },
      { title: "协议签署状态", dataIndex: "signStatusStr", scopedSlots: { customRender: "signStatusStr" }, width: 100, showOverflowTooltip: true },
      { title: "离职属性", dataIndex: "departureStatus", scopedSlots: { customRender: "departureStatus" }, width: 100, showOverflowTooltip: true },
      { title: "未签约时长（天）", dataIndex: "notSignDay", scopedSlots: { customRender: "notSignDay" }, width: 100, showOverflowTooltip: true },
      {
        title: "核对结果",
        dataIndex: "checkResultStr",
        scopedSlots: { customRender: "checkResultStr" },
        width: 100,
        showOverflowTooltip: true,
      },
      { title: "差异说明", dataIndex: "checkRemark", scopedSlots: { customRender: "checkRemark" }, width: 100, showOverflowTooltip: true },
      {
        title: "处理状态",
        dataIndex: "processStatus",
        scopedSlots: { customRender: "processStatus" },
        width: 100,
        showOverflowTooltip: true,
        type: 'custom',
        customRender: (text, item) => {
          if (text) {
            switch (text) {
              case 'Success':
                return '已处理';
              case 'Failed':
                return '处理失败';
              case 'Processing':
                return '处理中';
              default:
                return '待处理'
            }
          }
          return '待处理';
        }
      },
      { title: "处理结果", dataIndex: "handleResult", scopedSlots: { customRender: "handleResult" }, width: 100, showOverflowTooltip: true },
      {
        title: this.l("Actions"),
        dataIndex: "actions",
        sorter: false,
        width: 120,
        align: "center",
        fixed: 'right',
        scopedSlots: { customRender: "actions" }
      }
    ];
    this.actionsType = {
      type: "project",
      isShow: true,
      fns: {
        dropList: [
          {
            granted: this.isGranted("Pages.SignCheckResult.CheckDetail"),
            name: this.l("查看详情"),
            icon: "eye",
            width: "30%",
            fn: (data) => {
              ModalHelper.create(DetailInfo, {
                id: data.id
              }, {
                isChange: true,
                width: "800px",
                heigth: "600px",
              });
            },
          }
        ],
      },
    };
    this.getAllProjectAgreements();
    this.searchData();
  }
}
</script>
<style scoped>
.up-item-select {
  width: 95%;
}
</style>
