<template>
  <div class="u-container">
    <a-steps :current="current" class="u-step">
      <a-step v-for="item in steps" :key="item.title" :title="item.title">
        <a-icon slot="icon" :type="item.icon" />
      </a-step>
    </a-steps>
    <div class="steps-content">
      <!--选择项目和协议-->
      <div v-if="current === 1">
        <div class="u-item">
          <label>项目名称：</label>
          <a-input 
            class="u-input-item"
            v-model="projectInfo.projectName"
            placeholder="请选择项目"
            @click="selectProjectModal"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a-input
            class="u-input-item"
            v-model="projectInfo.projectCode"
            placeholder="项目编码"
            disabled
          />
        </div>
        <div class="u-item">
          <label>项目协议：</label>
          <a-select 
              class="u-input-item"
              v-model="currentProjectAgreementId"
              placeholder="请选择项目协议"
            >
              <a-select-option v-for="item in allProjectAgreements" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </div>
      </div>
      <!--上传导入文件-->
      <div v-if="current === 0">
        <a-row :gutter="24" class="left">
          <a-col span="17">
            <div style="color:red;text-indent:7px;">
              导入须知<br />
              1、员工身份证号、手机号、姓名、所在项目必须导入；<br />
              2、请按照导入模板导入数据，如无模板请先下载模板。
            </div>
            <a-upload-dragger 
                :file-list="fileList" 
                :remove="handleRemove" 
                :before-upload="beforeUpload"
                :multiple="false"
                :accept="'.xls,.xlsx'"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  点击或者将文件拖拽到此处上传
                </p>
                <p class="ant-upload-hint">
                  只支持上传单个excel文件
                </p>
            </a-upload-dragger>
          </a-col>
          <a-col span="7">
            <a-button @click="handleDownloadTemplate">下载模板</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="steps-action">
      <a-button v-if="current > 0" style="margin-left: 8px" @click="prevStep">
        上一步
      </a-button>
      &nbsp;&nbsp;
      <a-button v-if="current < steps.length - 1" :loading="uploading" :disabled="uploading" type="primary" @click="nextStep">
        下一步
      </a-button>
      &nbsp;&nbsp;
      <a-button
        v-if="current == steps.length - 1"
        type="primary"
        @click="handleSendSignture"
        :loading="uploading" 
        :disabled="uploading"
      >
        确定
      </a-button>
      &nbsp;&nbsp;
      <a-button
        v-if="current == steps.length - 1"
        type="default"
        @click="handleClose"
      >
        关闭
      </a-button>
    </div>
  </div>
</template>
<script>
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import SelectPageList from "@/components/linggong/select-page-list";
import axios from "axios";
import { AppConsts } from '@/abpPro/AppConsts';
import { fileDownloadService } from "@/shared/utils";

export default {
  name: 'batchSendIndex',
  mixins: [ModalComponentBase],
  data () {
    return {
      current: 0,
      steps: [
        {title: '上传导入文件', icon: 'select'},
        {title: '选择比对的协议', icon: 'import'}
      ],
      commonService: null,
      currentProjectAgreementId: undefined,
      projectInfo: {
        projectName: undefined,
        projectCode: '',
        projectId: null
      },
      allProjectAgreements: [],
      fileList: [],
      uploading: false
    }
  },
  methods: {
    nextStep () {
      if (this.current === 0) {
        if (!this.fileList || this.fileList.length <= 0) {
          abp.message.warn("请先上传excel文件");
          return false;
        }
      }
      this.current = this.current + 1;
    },
    prevStep () {
      this.current = this.current - 1;
    },
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "750px",
          heigth: "550px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          if (data.length !== 1) {
            abp.message.warn("只能选择一个项目");
            return;
          }
          
          this.projectInfo.projectCode = data[0].description; //data.id;
          this.projectInfo.projectName = data[0].name; //data.name;
          this.projectInfo.projectId = data[0].id;
          this.getProjectAgreements();
        }
      });
    },
    getProjectAgreements () {
      this.commonService.get({
        url: '/api/services/app/UnnaturalPersonProjectAgreement/GetListByProjectId',
        params: {
          projectId: this.projectInfo.projectId,
          isInvalid: false
        }
      }).then(res => {
        if (res && res.length > 0) {
          res.map(item => {
            this.allProjectAgreements.push({
              label: item.name,
              value: item.id,
              ...item
            })
            return null;
          });
        }
      });
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    uploadFile (url, message) {
      const { fileList } = this;
      if (!fileList || fileList.length !== 1) {
        abp.message.warn('未选择文件或文件数量大于1');
        return false;
      }
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('file', file);
      });
      this.uploading = true;
      const request = axios.create();
      
      let options = {
          method: "post",
          url: url,
          headers: {
              Accept: "application/x-www-form-urlencoded,multipart/form-data",
              Authorization: "Bearer " + abp.auth.getToken()
          },
          data: formData
      };
      request.request(options).then(res => {
        this.uploading = false;
        if (res.status === 200) {
          if (res.data.success) {
            if (res.data.result) {
              abp.message.error("导入异常，请参阅下载的excel文件");
              fileDownloadService.downloadTempFile(res.data.result);
            } else {
              abp.message.success(message);
              this.success(true);
            }
          } else {
            abp.message.error(res.data.error);
          }
        } else {
          abp.message.error(res.statusText);
        }
      }).catch(e => {
        this.uploading = false;
        var response = e.response;
        abp.message.error(response?.data?.error?.message ? response?.data?.error?.message : '上传出错');
        console.error(e);
      });
    },
    beforeUpload (file) {
      let index = file.name.lastIndexOf('.');
      let fileExt = '';
      if (index >= 0) {
        fileExt = file.name.substring(index + 1).toLowerCase();
      }
      if (fileExt != 'xls' && fileExt != 'xlsx') {
        abp.message.warn('只能上传xls，xlsx文件');
        return false;
      }
      if (this.fileList.length >= 1) {
        abp.message.warn('只能选择一个文件');
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
        return false;
      }
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleDownloadTemplate () {
      window.open('/fileTemplete/线下提报信息导入模板.xlsx');
    },
    handleSendSignture () {
      if (!this.currentProjectAgreementId || this.currentProjectAgreementId === '') {
        abp.message.warn('请选择项目协议');
        return false;
      }
      abp.message.confirm('提示','导入后会覆盖上次导入的数据，是否确认导入？', (res) => {
        if (res) {
          let url = AppConsts.remoteServiceBaseUrl + `/api/services/app/UPersonSignCheck/ImportExcel?ProjectAgreementId=${this.currentProjectAgreementId}`;
          this.uploadFile(url, '导入成功，请查看结果')
        }
      });
    },
    handleClose () {
      this.close();
    }
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  }
}
</script>
<style scoped>
.u-container {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px 0 15px;
}
.u-step {
  width: 95% !important;
  margin: 0 auto;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 5px;
}
.u-input-item {
  width:200px;
  margin:0 5px; 
}
.steps-action {
  margin-top: 24px;
  text-align: center;
}
.u-item {
  width: 99%;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
}
.left {
  text-align: left;
  text-indent: 8px;
}
.ant-upload-list-item-card-actions {
  top: -1px !important;
}
.ant-upload-list-item-info {
  width:80%;
}
</style>
